import React from 'react'
import "../../styles/Home.css"
import "../../styles/Carousel.css"
import { Carousel } from 'react-responsive-carousel'


export const Home = () => {

  return (
    <div>

      <Carousel
        transitionTime={500}
        interval={3000}
        autoPlay
        infiniteLoop
        centerMode
        centerSlidePercentage={33.3}
      >
        <div className="carousel-image">
          <img src={require("../../assets/kia2.jpeg")}></img>
        </div>
        <div className="carousel-image">
          <img src={require("../../assets/img2.jpg")}></img>
        </div>
        <div className="carousel-image">
          <img src={require("../../assets/img3.jpg")}></img>
        </div>
        <div className="carousel-image">
          <img src={require("../../assets/img4.jpeg")}></img>
        </div>
        <div className="carousel-image">
          <img src={require("../../assets/img5.jpg")}></img>
        </div>
      </Carousel>

      <div className="a-propos" id="about-section">
        <div className="section-title red with-animated-underline-larger with-animated-underline">À PROPOS DE NOUS</div>
        <div className="a-propos-text">Print Zone Promo est une entreprise d'impression de grand format. Nous sommes spécialisés dans l'impression numérique de produits tels que les enseignes, les habillages de voitures, les découpes de vinyle, les autocollants, les décalques, les panneaux, les affiches, les bannières, les drapeaux à plumes, les tentes personnalisées, les couvertures de table et bien plus encore. Nous offrons des services d'impression et d'installation. Veuillez nous envoyer un courriel ou nous appeler pour voir ce que nous pouvons vous offrir.</div>
      </div>

      {/* about section */}
      <div className="about-section" id="services">
        <div className="img-container">
          <p className="img-title with-animated-underline red">Graphisme</p>
          <img className="about-img about-img-1" src={require("../../assets/img6.jpg")}></img>
          <p className="img-desc">PrintZone Promo propose une conception graphique adaptée à l’image de votre entreprise; de la création de votre logo jusqu’à votre site web.</p>
        </div>
        <div className="img-container">
          <p className="img-title with-animated-underline blue">Impression</p>
          <img className="about-img about-img-2" src={require("../../assets/img7.jpg")}></img>
          <p className="img-desc">Impressions numériques, grands formats, découpes de vinyle, véhicules. Pour nos services sur mesure et personnalisés, une création et réalisation selon vos attentes. Nous travaillons en étroite collaboration avec vous pour optimiser votre image corporative.</p>
        </div>
        <div className="img-container">
          <p className="img-title with-animated-underline yellow">Installation</p>
          <img className="about-img about-img-3" src={require("../../assets/img8.jpg")}></img>
          <p className="img-desc">Nous vous offrons toutes notre expertise et notre savoir faire, nous avons des solutions à toute vos questions et nous prenons votre succès à cœur.</p>
        </div>

      </div>

      <div id="clients-section" className="clients-section">

        <div className="clients-title section-title red with-animated-underline-larger with-animated-underline">CLIENTÈLE</div>
        <div className="client-logos">
          {/* <img className="client-logo" src={require("../../assets/hlogo.png")}></img>
          <img className="client-logo" src={require("../../assets/atlaslogo.png")}></img>
          <img className="client-logo" src={require("../../assets/s16logo.png")}></img>
          <img className="client-logo" src={require("../../assets/constructionsirardlogo.png")}></img>
          <img className="client-logo" src={require("../../assets/hqlogo.jpg")}></img>
          <img className="client-logo" src={require("../../assets/chefpandalogo.jpg")}></img> */}
          <img className="client-logo" src={require("../../logos/hgregoire.png")}></img>
          <img className="client-logo" src={require("../../logos/bristol.png")}></img>
          <img className="client-logo" src={require("../../logos/canino.png")}></img>
          <img className="client-logo" src={require("../../logos/hqelectrique.png")}></img>
          <img className="client-logo" src={require("../../logos/pastaverawhite.png")}></img>
          <img className="client-logo" src={require("../../logos/atlas.png")}></img>
          <img className="client-logo" src={require("../../logos/dpi.png")}></img>
          <img className="client-logo" src={require("../../logos/sirard.png")}></img>
          <img className="client-logo" src={require("../../logos/raggio.png")}></img>



        </div>
        {/* <div className="client-logos">
          <img className="client-logo" src={require("../../assets/pastaveralogo.jpg")}></img>
        </div> */}
      </div>
    </div>
  )
}
